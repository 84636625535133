@use "mq" as *;

.f-zero-f-sec {
  width: 100%;
  height: 60vh;
  @include mq($until: tablet) {
    height: fit-content;
  }
  .container {
    height: 100%;
    .row {
      height: 100%;
      .col-md-12 {
        height: 100%;
        display: flex;
        .f-zero-f-sec-whapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          align-self: center;
          .f-z-f-img-whapper {
            width: 100%;
            height: 100%;
            object-fit: cover;
            img {
              @include img-mix;
            }
          }
          .f-z-f-content {
            text-align: center;
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }
}
