.single-celebrity-banner {
  
  .title-contner {
    .title {
      font-size: calc(20px + 4vmin);
      font-weight: 900;
    }

    .sub-title {
      font-size: calc(15px + 2vmin);
    }
  }

  .sec-dsc {
    p {
      @include font-normal-style;
      color: var(--content-color);

      strong {
        color: var(--color-primary);
        font-weight: 600;
      }
    }
  }

  .banner-img-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: calc(22rem + 20vmin);
      width: 100% !important;
      object-fit: cover;
    }
  }
}

.main-block-sec {
  width: 100%;
  padding-bottom: 15rem !important;
  .row {
    justify-content: space-between;
  }
  .common-bio-wrapper {
    background: var(--color-bg-white-smoke);
    padding: calc(2vmin + 20px);
    display: block;

    .title {
      font-weight: 900;
      font-family: var(--secondary-font) !important;
    }

    a {
      color: var(--color-primary) !important;
      transition: 0.3s;
      text-decoration: underline !important;
      &:hover {
        color: #6b6b6b !important;
      }
    }

    .wp-block-image {
      .aligncenter {
        float: left;
        width: 100%;
        img {
          margin: 1rem 0;
          width: 100%;
        }
      }  
    }  

    p {
      margin: 1rem 0;
    }

    table {
      margin: 15px 0;
      width: 100%;
      margin-top: 15px;
      tbody {
        width: 100%;
        tr {
          border-bottom: unset !important;
          margin-bottom: 2vmin;
          font-size: calc(15px + 0.5vmin);
          &:nth-child(odd) {
              background: #ededed87;
          }
          td {
            padding: 0.4rem 1rem;
          }
        }
        tr > th:first-child {
          width: 50px;
          height: 50px;
        }
        tr th {
          > span {
            width: calc(20px + 2vmin) !important;
            height: calc(20px + 2vmin) !important;
            overflow: hidden;
            border-radius: 30px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--button-ternary-color);
            svg {
              fill: var(--button-primary-color);
              height: 20px;
              width: 20px;
            }
          }
        }
      }
      border: none;
    }
    .table > :not(caption) > * > * {
      border-bottom: unset !important;
    }
  }
  .common-connet-sec {
    color: var(--content-color);
    h4.title {
      font-weight: 900;
      font-family: var(--secondary-font) !important;
      color: var(--color-primary);
    }
    p strong {
      font-weight: 600;
      color: var(--color-primary);
    }
  }
}