@tailwind base;

/* Write your own custom base styles here */

/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Write you own custom component styles here */

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

/* Your own custom utilities */

.has-text-align-left {
  @apply text-left;
}

.has-text-align-center {
  @apply text-center;
}

.has-text-align-right {
  @apply text-right;
}

.has-large-font-size {
  @apply text-4xl;
}

.alignfull {
  @apply w-screen relative;

  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  right: 50%;
}

.wp-block-image img {
  @apply max-w-full mt-2;
}
.wp-block-image.aligncenter {
  @apply text-center;
}
.wp-block-image.alignfull img,
.wp-block-image.alignwide img {
  @apply w-full;
}
.wp-block-image .alignleft,
.wp-block-image .alignright,
.wp-block-image .aligncenter,
.wp-block-image.is-resized {
  @apply table ml-0 mr-0;
}
.wp-block-image .alignleft > figcaption,
.wp-block-image .alignright > figcaption,
.wp-block-image .aligncenter > figcaption,
.wp-block-image.is-resized > figcaption {
  @apply table-caption;

  caption-side: bottom;
}

.wp-block-image .alignleft {
  @apply float-left mr-4;
}
.wp-block-image .alignright {
  @apply float-right ml-4;
}
.wp-block-image .aligncenter {
  @apply m-auto;
}

.wp-block-button a,
.wp-block-file a.wp-block-file__button {
  @apply bg-blue-500 text-white no-underline py-2 px-4;
}

.wp-block-button a:hover,
.wp-block-file a.wp-block-file__button:hover {
  @apply bg-blue-400 cursor-pointer;
}

.wp-block-file a:first-of-type {
  @apply mr-4;
}

.wp-block-cover {
  @apply flex flex-wrap justify-center items-center bg-cover bg-center overflow-hidden;

  min-height: 430px;
}

.wp-block-verse {
  @apply font-sans;
}

.wp-block-media-text {
  @apply grid grid-cols-2 gap-4;
}
