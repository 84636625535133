@use "mq" as *;

a {
  text-decoration: none !important;
}

.header {
  .header-side-warper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    @include mq($until: tablet) {
      display: none;
    }
    .header-side-nav {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          // line-height: 2;
          &:hover {
            a {
              color: var(--color-primary);
              font-weight: bold;
            }
          }
          a {
            color: var(--color-primary-gray);
            font-size: calc(8px + 0.8vmin);
            text-decoration: none;
            transition: 0.15s;
            padding-top: calc(2px + 1vmin);
            padding-bottom: calc(2px + 1vmin);
          }
        }
      }
    }
    .arrow-warper {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 1px solid #575757;
      padding: 0.5rem;
      display: grid;
      place-content: center;

      a {
        display: grid;
        place-content: center;
      }

      svg {
        color: #ccc;
        padding: 5px;
      }

      &:hover svg {
        color: var(--color-primary);
      }
    }
  }

  .img-card-wraper {
    width: 100%;
    position: relative;
    &:after {
      @include common-after-before;
      background-color: var(--color-bg-primary);
      top: 5rem;
      left: -19px;
    }
    &::before {
      @include common-after-before;
      background-color: var(--color-bg-primary);
      bottom: 5rem;
      right: -19px;
    }
  }

  img {
    @include img-mix;
  }
}

.header-content {
  width: 100%;
  margin-top: calc(10px + 5vmin);

  @include mq($until: desktop) {
    margin-top: 0 !important;
  }
  .small-title {
    color: var(--color-primary-gray);
    text-transform: uppercase;
    margin-bottom: calc(5px + 0.5vmin);

    a {
      color: inherit;
    }
  }
  .header-title {
    font-size: calc(34px + 2vmin);
    font-weight: 900;
  }
  .read-more {
    position: relative;
    a {
      color: var(--color-primary);
      text-decoration: none;
      margin-right: 2vmin;
      font-weight: bold;
      transition: 0.35s;
      &:after {
        content: "";
        width: calc(10px + 1vmin);
        position: absolute;
        top: 50%;
        border-bottom: 1px solid var(--color-primary);
        left: calc(120px + 3vmin) !important;
        transition: 0.35s;
      }
      &:hover {
        color: var(--color-primary-gray);
        &:after {
          width: calc(10px + 5vmin);
        }
      }
    }
  }
}

.latest-post-sec {
  position: relative;
  display: block;

  .sec-title-wrapper {
    @include sec-title-wrapper-mix;

    .sec-title {
      @include sec-title-mix;
      &:after {
        @include sec-title-after-mix;
        left: calc(10rem + 9vmin);
      }
    }

    .title-box-common {
      @include title-box-common-mix;
      width: calc(150px + 3rem);
      background-color: var(--color-bg-primary);
      top: 0;
      left: calc(5vmin + 5vmin);
    }
  }
  .post-grid-wrapper {
    @include sec-flex-wrapper-mix;
    .c-card-one {
      text-decoration: none !important;
      position: relative;
      @include c-card-one-flex-mix;
      &:hover {
        &:before {
          left: 0;
          transform: translateX(-19px);
        }
        &:after {
          right: 0;
          transform: translateX(19px);
        }
        transform: scale(1.05);
      }
      &:after {
        @include common-after-before;
        background-color: var(--color-bg-primary);
        top: 5rem;
        right: 0;
      }
      &:before {
        @include common-after-before;
        background-color: var(--color-bg-primary);
        top: 10rem;
        left: 0;
      }
      .img-wrapper {
        @include img-wrapper-mix;
      }
      .c-card-cat-title {
        @include c-card-cat-title-mix;
        text-decoration: none !important;
      }
      .c-card-title-wrapper {
        @include c-card-title-mix;
      }
    }
  }
}

.popular-post-sec {
  background-color: var(--color-bg-white-smoke);
  position: relative;
  display: block;
  .sec-title-wrapper {
    @include sec-title-wrapper-mix;
    .sec-title {
      @include sec-title-mix;
      &:after {
        @include sec-title-after-mix;
        left: calc(9rem + 20vmin);
      }
    }
    .title-box-common {
      @include title-box-common-mix;
      width: calc(150px + 4rem);
      background-color: var(--accent);
      top: 0;
      left: calc(3rem + 5vmin);
    }
    .icon-wrapper {
      @include icon-wrapper-mix;
    }
  }
  .post-grid-wrapper {
    @include sec-flex-wrapper-mix;
    .c-card-one {
      position: relative;
      @include c-card-one-flex-mix;
      &:hover {
        &:after {
          right: 0;
          transform: translateX(19px);
        }
        &:before {
          left: 0;
          transform: translateX(-19px);
        }
        transform: scale(1.05);
      }
      &:after {
        @include common-after-before;
        right: 0;
        top: 5rem;
        background-color: #e5f3fe;
      }
      &:before {
        @include common-after-before;
        left: 0;
        top: 10rem;
        background-color: #e5f3fe;
      }
      .img-wrapper {
        @include img-wrapper-mix;
      }
      .c-card-title-wrapper {
        @include c-card-title-mix;
      }
      .post-date {
        @include post-date-mix;
      }
    }
  }
}

.socialShare {
  position: relative;
  display: block;
  .sec-title-wrapper {
    @include sec-title-wrapper-mix;
    .sec-title {
      @include sec-title-mix;
      &:after {
        @include sec-title-after-mix;
        left: 19rem;
      }
    }
  }
  .sec-cont-wrapper {
    display: flex;
    a {
      background: #e0e0e0;
      display: inline-block;
      margin-right: 0.8rem;
      padding: 0.4rem 0.8rem;
      color: var(--color-primary);
      font-size: 20px;
      border-radius: 14px;
      cursor: pointer;
      transition: 0.3s;
      min-width: 45px;
      text-align: center;
      &:hover {
        background: var(--color-primary);
        color: #fff;
      }
    }
  }
}
.trending-post-sec {
  position: relative;
  display: block;
  .sec-title-wrapper {
    @include sec-title-wrapper-mix;
    .sec-title {
      @include sec-title-mix;
      &:after {
        @include sec-title-after-mix;
        left: 19rem;
      }
    }
    .title-box-common {
      @include title-box-common-mix;
      width: calc(150px + 3rem);
      background-color: var(--color-bg-primary);
      top: 0;
      left: calc(3rem + 7vmin);
    }
  }
  .post-grid-wrapper {
    @include sec-grid-wrapper-mix;
    .c-card-tow {
      @include c-card-tow-mix;
      transition: 0.35s;
      &:hover {
        &:after {
          right: 0;
          transform: translateX(19px);
        }
        &:before {
          left: 0;
          transform: translateX(-19px);
        }
        transform: scale(1.05);
      }
      &:after {
        @include common-after-before;
        right: 0;
        top: 10rem;
        background-color: var(--color-bg-primary);
      }
      &:before {
        @include common-after-before;
        left: 0;
        top: 5rem;
        background-color: var(--color-bg-primary);
      }
    }
    .img-wrapper {
      width: 100%;
      height: 100%;
      position: absolute;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .content-wrapper {
      @include content-wrapper-mix;
      padding: 6rem 1rem 1rem;
      .c-card-tow-title-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .news-cat-sec {
          padding: 3px 5px;
          background-color: rgba(255, 255, 255, 0.08);
          a {
            color: var(--color-white);
            text-transform: uppercase;
          }
          span {
            font-size: 12px;
            color: var(--color-white);
          }
        }
        p {
          margin: 0px;
          padding-right: 2vmin;
          span {
            font-size: 12px;
            color: var(--color-white);
          }
          .dot {
            padding: 0 0.5rem;
          }
        }
      }
      article {
        color: var(--color-white);
        .content-title {
          @include font-normal-style;
          color: inherit;
          font-size: calc(15px + 0.8vmin);
          font-weight: 600;
          letter-spacing: -0.09px;
          line-height: 1.4;
          text-align: left;
          color: #fff;
        }
      }
    }
  }
}

.celebrity-post-sec {
  background-color: var(--accent-light);
  position: relative;
  display: block;
  .sec-title-wrapper {
    @include sec-title-wrapper-mix;
    .sec-title {
      @include sec-title-mix;
      &:after {
        @include sec-title-after-mix;
        left: calc(15rem + 8vmin);
      }
    }
    .title-box-common {
      @include title-box-common-mix;
      width: calc(150px + 5vmin);
      background-color: var(--accent);
      top: 0;
      left: calc(3rem + 8vmin);
    }
    .icon-wrapper {
      @include icon-wrapper-mix;
    }
  }
  .post-grid-wrapper {
    @include sec-flex-wrapper-mix;
    .c-card-one {
      position: relative;
      @include c-card-one-flex-mix;
      &:hover {
        &:after {
          right: 0;
          transform: translateX(19px);
        }
        &:before {
          left: 0;
          transform: translateX(-19px);
        }
        transform: scale(1.05);
      }
      &:after {
        @include common-after-before;
        right: 0;
        top: 5rem;
        background-color: var(--accent);
      }

      &:before {
        @include common-after-before;
        left: 0;
        top: 10rem;
        background-color: var(--accent);
      }
      .img-wrapper {
        @include img-wrapper-mix;
      }
      .c-card-title-wrapper {
        @include c-card-title-mix;
      }
      .post-date {
        @include post-date-mix;
      }
    }
  }
}

.author-slider {
  position: relative;
  .sec-title-wrapper {
    @include sec-title-wrapper-mix;
    .sec-title {
      @include sec-title-mix;
      &:after {
        @include sec-title-after-mix;
        left: 11rem;
      }
    }
    .title-box-common {
      @include title-box-common-mix;
      width: calc(100px + 1rem);
      background-color: var(--color-bg-primary);
      top: 0;
      left: 5rem;
    }
  }
  .post-grid-wrapper {
    @include sec-flex-wrapper-mix;

    .c-auth-card {
      @include c-card-one-flex-mix;
      position: relative;

      .auth-img-wrapper {
        overflow: hidden;

        img {
          transition: 0.3s;
        }
      }

      &:hover {
        .auth-img-wrapper img {
          transform: scale(1.05);
        }
      }
      .auth-img-wrapper {
        height: 100%;
        width: 100%;
        img {
          @include img-mix;
        }
      }
      .img-wrapper-overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        display: none;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 1;
        transition: 0.35s;
        span {
          font-size: calc(15px + 1vmin);
          color: var(--color-white);
        }
      }
    }
  }
  .arrow-sec {
    position: absolute;
    display: flex;
    justify-content: space-between;
    left: 2%;
    right: 2%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    .left-arrow {
      @include arrow-mix;
    }

    .right-arrow {
      @include arrow-mix;
    }
    svg {
      color: #c8c8c8;
      padding: 10px;
    }

    .swiper-button-prev,
    .swiper-button-next {
      &::after {
        display: none;
      }
    }
  }

  .swiper-pagination-bullet-active {
    background: #000 !important;
  }
}

.entertain-sec {
  background-color: var(--accent-light);
  position: relative;
  display: block;
  padding-bottom: 15rem !important;
  .sec-title-wrapper {
    @include sec-title-wrapper-mix;
    .sec-title {
      @include sec-title-mix;
      &:after {
        @include sec-title-after-mix;
        left: calc(8rem + 30vmin);
      }
    }
    .title-box-common {
      @include title-box-common-mix;
      width: calc(150px + 20vmin);
      background-color: var(--accent);
      top: 0;
      left: calc(0vmax + 11vmin);
    }
    .icon-wrapper {
      @include icon-wrapper-mix;
      svg {
        stroke: var(--accent);
        fill: var(--accent) !important;
      }
    }
  }

  .post-grid-wrapper {
    @include sec-flex-wrapper-mix;

    .c-card-one {
      position: relative;
      @include c-card-one-flex-mix;
      transition: 0.35s;

      &:hover {
        &:after {
          right: 0;
          transform: translateX(19px);
        }

        &:before {
          left: 0;
          transform: translateX(-19px);
        }

        transform: scale(1.05);
      }

      &:after {
        @include common-after-before;
        right: 0;
        top: 5rem;
        background-color: var(--accent);
      }

      &:before {
        @include common-after-before;
        left: 0;
        top: 10rem;
        background-color: var(--accent);
      }

      .img-wrapper {
        @include img-wrapper-mix;
        svg {
          stroke: var(--accent) !important;
          fill: var(--accent) !important;
        }
      }
      .c-card-title-wrapper {
        @include c-card-title-mix;
      }
      .post-date {
        @include post-date-mix;
      }
    }
  }
}