@use "mq" as *;

.single-header {
  display: block;
  position: relative;
  height: 60vh;
  .single-header-img-wrapper {
    width: 100%;
    height: 100%;
    img {
      @include img-mix;
    }
  }
}

.single-content {
  position: relative;
  background-color: var(--color-white);
  padding-bottom: 15rem !important;
  .single-page-title-card {
    background-color: var(--color-white);
    padding: calc(20px + 2vmin);
    position: relative;
    top: calc(-5rem + -1vmin);
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.05);
    margin-bottom: -10vh;

    .title_wrapper {
      margin-bottom: calc(10px + 2vmin);
      .blog-title {
        margin: 0px;
        font-weight: 900;
        font-size: calc(20px + 2.2vmin);
        @include font-normal-style;
      }
    }
    article.title-dsc {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include mq($until: tablet) {
        flex-direction: column;
      }
      p {
        margin: 0px;
        font-size: 16px;
        line-height: 1.63;
        text-align: left;
        @include font-normal-style;
        span {
          color: var(--color-primary-gray);
        }
        .division {
          padding: 0 2vmin 0 0;
          color: var(--color-primary-gray);
          a {
            color: var(--color-primary-gray);
          }
        }
        .dot {
          padding: 0 2vmin 0 0;
          color: var(--color-primary-gray);
        }
      }
      .social-sec-icon {
        @include social-sec-icon-mix;
        @include mq($until: tablet) {
          margin-top: calc(20px + 2vmin);
          ul {
            padding-left: 0px;
          }
        }
      }
    }
  }
  .main-content-wrapper {
    .main-content-title {
      h2 {
        font-family: var(--secondary-font);
      }
    }
    table {
      width: 100%;
      margin-top: calc(20px + 2vmin);
    }
    .content-sec {
      width: 100%;
      position: relative;
      a {
        color: var(--color-primary) !important;
        transition: 0.3s;
        text-decoration: underline !important;
        &:hover {
          color: #6b6b6b !important;
        }
      }
      overflow: hidden;
      p:first-child {
        margin-top: 0 !important;
      }
      P {
        @include font-normal-style;
        font-size: 20px;
        margin-top: calc(20px + 2vmin);
      }
      .content-img-warper {
        width: 100%;
        height: calc(200px + 10vmax);
        display: block;
        img {
          width: 100%;
          object-fit: cover;
          height: 100%;
        }
      }
      .eyelash {      
        &:before {
          content: "";
          background: url(/eyelash.svg);
          background-repeat: no-repeat;
          display: block;
          background-size: 4rem;
          height: 2rem;
          width: 4rem;
          margin: 0.3rem auto 0;
        }      
        &:after {
          content: "";
          background: url(/eyelash.svg);
          background-repeat: no-repeat;
          display: block;
          background-size: 4rem;
          height: 2rem;
          width: 4rem;
          margin: 0.3rem auto 0;
          padding-bottom: 0.3rem;
          transform: rotate(180deg);
        }
      }

      .lyrics {
        display: flex;
        margin-top: calc(20px + 2vmin);
        img {
          width: 50%;
          float: left;
        }
        .lyrics-content {
          height: 36rem;
          overflow-y: scroll;
          padding: 0 25px;
          -moz-animation-name: move;
          -o-animation-name: move;
          animation-name: move;
          font-family: Playfair Display;
          &::-webkit-scrollbar-track {	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.349);background-color: #F5F5F5;}
          &::-webkit-scrollbar { width: 6px; background-color: #F5F5F5; }
          &::-webkit-scrollbar-thumb { background-color: #000000; } 
          p {
            margin-top: calc(10px + 1vmin);
          }     
        }       
      }
    }
  }

  .tag-sec {
    .card-chip-left {
      display: flex;
      flex-direction: row;
      gap: 2vmin;
      flex-wrap: wrap;
      .chip-card {
        background: #e0e0e0;
        color: #6a6969;
      }
      a {
        display: grid;
        width: max-content;
        padding: 5px 10px;
        border-radius: calc(10px + 1vmin);
        font-size: 16px;
      }
    }
  }

  .single-author-sec {
    width: 100%;
    border: 1px solid rgba(180, 180, 180, 0.2);
    border-radius: calc(5px + 0.1vmin);
    display: flex;
    flex-direction: row;
    padding: calc(10px + 1vmin) calc(20px + 2vmin);
    @include mq($until: tablet) {
      flex-direction: column;
    }

    .author-content-sec {
      margin-left: calc(10px + 1vmin);
      .single-author-sec-title-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: calc(10px + 1vmin);
        @include mq($until: tablet) {
          flex-direction: column;
          justify-content: center;
        }
        .author-title {
          font-size: calc(10px + 1vmin);
          color: var(--color-primary);
          margin-bottom: 0px;
          a {
            color: var(--color-primary);
            font-weight: 600;
          }
          @include mq($until: tablet) {
            display: flex;
            justify-content: center;
            font-size: calc(10px + 2vmin);
          }
        }

        .social-sec-icon {
          @include social-sec-icon-mix;
          @include mq($until: tablet) {
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: calc(10px + 1vmin);
            ul {
              padding-left: 0px;
              justify-content: center;
              gap: calc(10px + 3vmin);
            }
          }
        }
      }
    }
    .author-img-card {
      width: fit-content;
      align-self: center;
      max-width: 8rem;

      @include mq($until: tablet) {
        margin-bottom: calc(20px + 2vmin);
      }
      img {
        height: calc(100px + 2vmin);
        width: calc(100px + 32vmin);
        border-radius: 50%;
      }
    }
  }
}

.aside-bar {
  height: fit-content;
  position: sticky;
  top: 0;
  .recent-card-sec {
    width: 100%;
    background-color: var(--color-bg-white-smoke);
    padding: calc(10px + 2vmin);

    .pwa-link-button {
      background: var(--color-primary);
      color: #fff;
      padding: calc(4px + 0.2rem) calc(4px + 0.5rem);
      border-radius: 3px;
    }

    .social {
      padding: 0;
      li {
        background: var(--color-primary);
        border-radius: 3px;
        display: inline-block;
        margin-right: 10px;
        a {
          margin: 0.5rem 0.8rem;
          width: 100%;
          display: flex;
          svg {
            color: var(--color-white);
          }
          display: inline;
        }
      }
      a {
        display: inline-block;
        color: var(--color-white);
        svg {
          color: var(--color-white);
        }
      }
    }

    .r-c-title {
      margin: 0 0 calc(10px + 2vmin) 0;
      padding: 0px;
      @include sidebar-title-mix;
    }
    .c-recent-card {
      display: flex;
      flex-direction: row;
      text-decoration: none;
      justify-content: flex-start;
      margin-bottom: calc(10px + 1.5vmin) !important;

      .c-recent-card-img-wrapper {
        width: calc(3rem + 5vmin) !important;
        min-width: calc(3rem + 5vmin) !important;
        height: calc(3rem + 5vmin) !important;
        display: block;
        img {
          @include img-mix;
        }
      }
      .c-card-content {
        margin-left: calc(10px + 0.5vmin);
        display: flex;
        flex-direction: column;
        justify-content: center;
        .c-c-c-title {
          color: var(--color-primary);
          font-size: calc(12px + 0.5vmin);
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          font-family: var(--secondary-font);
        }
        .c-dsc {
          font-size: calc(10px + 0.5vmin);
          margin-bottom: 0;
          span {
            color: #6b6b6b;
          }
        }
      }
    }
  }
  .category-card {
    width: 100%;
    background-color: var(--color-bg-white-smoke);
    padding: calc(10px + 2vmin);
    .c-c-title {
      @include sidebar-title-mix;
    }
    .button-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .c-btn {
        border-radius: 15px;
        padding: 4px calc(10px + 1vmin);
        margin-top: calc(10px + 1vmin);
        margin-bottom: calc(10px + 0.5vmin);
        margin-right: calc(10px + 1vmin);
        text-decoration: none;
      }
      .artists {
        color: #105bc9;
        background-color: #e5f0ff;
      }
      .awards {
        color: #ff7515;
        background-color: #ffefe2;
      }
      .comedy {
        color: #ff9f0f;
        background-color: #fff6e8;
      }
      .bollywood {
        color: #33c2ff;
        background-color: #ebf9ff;
      }
      .box-office {
        color: #3bb900;
        background-color: #ecfbe6;
      }
      .thriller {
        color: #ff1943;
        background-color: #ffe8ec;
      }
      .celebrities {
        color: #e63946;
        background-color: #ffe5e7;
      }
      .entertainment {
        color: #640adc;
        background-color: #ede7fd;
      }
      .hollywood {
        color: #18bfcb;
        background-color: #e6ffff;
      }
      .web-series {
        color: #ebc427;
        background-color: #fffee6;
      }
      .tv-show {
        color: #6923a9;
        background-color: #ffe6ff;
      }
      .web-thriller {
        color: #640adc;
        background-color: #ede7fd;
      }
    }
  }
}
