.blog-page-main-sec{
    padding-bottom: 15rem !important;
   .blog-page-dsc{
       display: flex;
       justify-content: center;
       flex-direction: column;
       .celebrity-image {
            text-align: center;
            height: 100%;
        }
       .page-title{
           font-weight: 900 !important;
           @include font-normal-style;
       }
   }
}
