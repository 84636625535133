@use "mq" as *;

:root {
  --color-primary: #000000;
  --color-primary-gray: #6b6b6b;
  --color-primary-gary-one: #8a8a8a;
  --color-primary-gary-tow: #b4b4b4;
  --content-color: #575757;

  --accent: #894ed3;
  --accent-light: #894ed3;

  --color-bg-white-smoke: #fafafe;
  --color-bg-primary: #f5f5f5;

  // --color-bg-secondary: #f7eae1;
  --color-bg-ternary: #eaf0ee;
  // --color-bg-deffer: #fbf2f3;
  --color-white: #ffffff;

  // --entertain-sec-asset-bg: #f7e1d2;
  // --celebrity-sec-asset-bg: #fbe2e5;

  --button-primary-color: #894ed3;
  --button-secondary-color: #dec9fb;
  --button-ternary-color: #f3edfb;

  --primary-font: "Noto Serif KR", serif;
  --secondary-font: "Jost", sans-serif;
}

// for celebrate single

// for celebrate single

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--primary-font);
}

@mixin sec-title-wrapper-mix {
  display: flex;
  justify-content: space-between;
  height: fit-content;
  position: relative;
  align-items: center;
  min-height: calc(50px + 1.1rem);

  display: inline-flex !important;

  .title-box-common {
    width: 80% !important;
  }

  .sec-title:after {
    left: unset !important;
    width: calc(2rem + 10vmin) !important;
    margin-left: 2vmin;
  }
}

@mixin sec-title-mix {
  display: block;
  z-index: 1;
  font-weight: 900;
  a {
    color: inherit;
  }
}

@mixin sec-title-after-mix {
  content: "";
  position: absolute;
  width: calc(50px + 5vmin);
  height: 0px;
  border-bottom: 1px solid var(--color-primary);
  top: 50%;
  @include mq($until: tablet) {
    display: none;
  }
}

@mixin title-box-common-mix {
  position: absolute;
  height: calc(50px + 1.1rem);
}

@mixin icon-wrapper-mix {
  width: calc(30px + 2vmin);
  @include mq($until: tablet) {
    width: fit-content;
  }
}

//  card mix in crd 1 type
@mixin sec-flex-wrapper-mix {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
}

@mixin c-card-one-flex-mix {
  flex: 1 1 calc(250px + 11vmin);
  width: 100%;
  transition: 0.35s;
}

@mixin sec-grid-wrapper-mix {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(calc(250px + 6vmin), 1fr));
  gap: 4rem;
}

@mixin sec-grid-wrapper-sub-menu-mix {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(calc(150px + 12vmin), 1fr));
  gap: 1rem;
}

@mixin c-card-ome-mix {
  display: grid;
  width: 100%;
  height: fit-content;
  text-decoration: none !important;
  z-index: 0;
}
@mixin img-wrapper-mix {
  height: calc(250px + 10rem);
  img {
    @include img-mix;
  }
}

@mixin c-card-cat-title-mix {
  font-size: 14px;
  color: var(--color-primary-gray);

  a {
    text-decoration: none !important;
    color: inherit;
    font-size: inherit;
    text-decoration: none;
    color: var(--color-primary-gray);
  }
}

@mixin c-card-title-mix {
  .title-content {
    font-family: var(--primary-font);
    font-size: calc(18px + 1vmin);
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--color-primary);
    text-decoration: none;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
@mixin post-date-mix {
  color: var(--color-primary-gary-tow);
  span {
    color: inherit;
  }
  .dot {
    padding: 5px;
  }
}

// card tow

@mixin c-card-tow-mix {
  display: grid;
  width: 100%;
  height: fit-content;
  position: relative;
  height: calc(300px + 10rem);
  transition: 0.35s;
  max-height: calc(300px + 10rem);
  overflow: hidden;
}

@mixin content-wrapper-mix {
  top: 40%;
  left: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000 91%);
  z-index: 1;
  position: absolute;
  width: 100%;
}

// auth card

// @mixin c-auth-card-mix {
//   width: 100%;
//   height: calc(300px + 10rem);
//   position: relative;
// }

//img comma
@mixin img-mix {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@mixin arrow-mix {
  height: calc(30px + 2vmin);
  width: calc(30px + 2vmin);
  border-radius: 50%;
  border: 0.5px solid var(--content-color);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
  svg {
    fill: var(--content-color) !important;
    stroke: var(--content-color) !important;
  }
}

@mixin font-normal-style {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

@mixin social-sec-icon-mix {
  width: fit-content;
  ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 0px;
    li {
      padding: 0 1vmin;
      a {
        width: 30px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
        text-decoration: none;
        border-radius: 2px;
        i {
          color: var(--color-primary-gary-one);
        }
      }
    }
  }
}

@mixin sidebar-title-mix {
  font-size: calc(12px + 1vmin);
  font-weight: 900;
}

.fix-nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

// after before mix card hover element
@mixin common-after-before {
  content: "";
  display: block;
  position: absolute;
  height: calc(150px + 5rem);
  width: 19px;
  transition: 0.35s;
  z-index: -1;
}

// css hover element
