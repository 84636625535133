.celebrity-page {
  .nav {
    background-color: transparent;
    ul {
      li {
        width: fit-content;
        .nav-icon-v {
          background-color: transparent;
          span {
            border-bottom: 2px solid var(--color-white);
          }
        }
        a {
          img {
            filter: invert(100%);
          }
        }
      }
    }
  }
}

.celebrity-banner {
  width: 100%;
  height: 60vh;
  position: relative;
  z-index: -1;
  .celebrity-banner-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(8, 8, 8, 0.295);
    width: 100%;
    .title {
      color: var(--color-white);
      font-size: calc(20px + 2vmin);
      font-weight: 900;
    }
  }
  .celebrity-banner-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    height: 100%;
    .c-card-banner {
      width: 100%;
      height: 60vh;
      min-height: 60vh;
      img {
        height: 100%;
        @include img-mix;
      }
    }
  }
}

.celebrity-card-sec {
  padding-bottom: 15rem !important;
  .filter-wrapper {
    display: none;
    position: absolute;
    right: 0;
    height: 80vh;
    top: 75vh;
    width: calc(200px + 15vmin);
    background-color: var(--button-ternary-color);
    z-index: 999;
    padding: calc(1rem + 3vmin) calc(1rem + 3vmin);
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    .cross-wraper {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 2vmin;
      right: 3vmin;
      float: right;
      display: flex;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .filter-wrapper-show {
    display: block !important;
  }

  .card-chip-whapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .card-chip-left {
      display: flex;
      flex-direction: row;
      gap: 2vmin;
      flex-wrap: wrap;
      width: 90% !important;
      input[type="checkbox"]:checked + label > .chip-card {
        background: var(--button-ternary-color);
        color: var(--button-primary-color);
        position: relative;
        display: flow-root;
        svg {
          margin-right: 0.3rem;
        }
      }
      .form-check-input:not(:checked) + label svg {
        display: none;
      }
      .chip-card {
        display: block;
        background: #e0e0e0;
        color: #6a6969;
        svg {
          margin-right: 5px;
        }
      }
      .active-chip {        
        background: var(--button-ternary-color);
        color: var(--button-primary-color);
        position: relative;
        display: flow-root;
        svg {
          margin-right: 0.3rem;
        }
      }
      span {
        display: grid;
        width: max-content;
        padding: 5px 10px;
        border-radius: calc(10px + 1vmin);
        margin: calc(10px + 0.5vmin) 0;
        font-size: 16px;
      }
    }
    .card-chip-right {
      position: relative;
      .c-btn {
        display: inline-block;
        font-weight: 400;
        line-height: 1.5;
        font-family: var(--secondary-font);
        color: var(--button-primary-color);
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        cursor: pointer;
        user-select: none;
        background-color: var(--button-ternary-color);
        border: 1px solid transparent;
        padding: 0.2rem 0.8rem;
        font-size: 1rem;
        border-radius: 0.9rem;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }
    }
  }
}

.celebrity-sec-card-whapper {
  @include sec-grid-wrapper-mix;
  .c-card-tow {
    @include c-card-tow-mix;
    &:hover {
      &:after {
        right: 0;
        transform: translateX(19px);
      }
      &:before {
        left: 0;
        transform: translateX(-19px);
      }
      transform: scale(1.05);
    }
    &:after {
      @include common-after-before;
      right: 0;
      top: 10rem;
      background-color: var(--color-bg-primary);
    }
    &:before {
      @include common-after-before;
      left: 0;
      top: 5rem;
      background-color: var(--color-bg-primary);
    }


  }
  .img-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    .celebrity-image {
      height: 100%;
      span {
        height: 100% !important;
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .content-wrapper {
    @include content-wrapper-mix;
    padding: 6rem 1rem 1rem;
    .c-card-tow-title-wrapper {
      .c-card-tow-title {
        color: var(--color-white);
        @include font-normal-style;
      }
    }
    article {
      color: var(--color-white);
      .content-title {
        @include font-normal-style;
        color: inherit;
        font-weight: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}
