@use "mq" as *;

html,
body {
  width: 100%;
}
body {
  font-family: sans-serif;
  font-family: var(--secondary-font) !important;
  font-size: calc(15px + 0.5vmin);
}
.highlightIcon {
  color: #000;
}

img {
  object-fit: cover;
}

img[src*="logo"] {
  object-fit: contain !important;
}

//  nav bar start
.nav {
  background-color: transparent;
  ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0px;
    padding: calc(10px + 1vmin) 0;
    li {
      width: fit-content;
      .nav-icon-v {
        width: 50px;
        height: 50px;
        background-color: var(--color-white);
        position: relative;
        span {
          border-bottom: 2px solid var(--color-primary);
          width: 30px;
          position: absolute;
          transition: 1s;
        }

        .span1 {
          left: 0;
          top: 20%;
        }

        .span2 {
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        .span3 {
          left: 0;
          bottom: 20%;
        }
      }

      .active {
        position: relative;
        span.span1 {
          transform: translateX(60%);
        }

        span.span2 {
          transform: translateX(-60%);
        }

        span.span3 {
          transform: translateX(60%);
        }
      }
      .nav-bar-logo {
        svg {
          width: fit-content;
          height: calc(20px + 1vmin);
        }
        img {
          width: calc(100px + 24vmin) !important;
        }
      }
    }
  }
}

.container {
  margin: auto;
}

// hamburger start
.hamburger {
  display: block;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding-top: calc(20px + 1vmin);
  height: 100%;
  transform: translateX(-100%);
  background-color: var(--color-white);
  transition: 1s;
  z-index: 2;

  &.active {
    scroll-padding: 5rem;
    position: fixed;
    left: 0;
    transform: translateX(0%);
  }

  .hamburger-logo {
    svg {
      width: fit-content;
      height: calc(20px + 1vmin);
    }
  }

  .menu-cross {
    position: absolute;
    right: 3vmin;
    top: calc(20px + 1vmin);
    width: calc(20px + 1vmin);
    height: calc(20px + 1vmin);
    display: flex;
    svg {
      height: 100%;
      width: 100%;
    }
  }

  .hamburger-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .hamburger-card-mobile {
    width: calc(200px + 10vmax);
    height: 100%;
    @include mq($from: tablet) {
      display: none;
    }
    .mobile-sub-menu {
      display: none;
      &:target {
        display: block !important;
      }
    }
    .animated {
      position: relative;
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          /* Sub Menu */
          a {
            display: block;
            background: var(--color-bg-primary);
            padding: 10px 15px;
            color: var(--color-text-primary);
            text-decoration: none;
            transition: 0.2s linear;
            .fa {
              width: 16px;
              text-align: center;
              margin-right: 5px;
              float: right;
            }
            svg {
              width: calc(10px + 0.2rem);
              display: inline-block;
            }
          }
          ul {
            li {
              a {
                background: #f8f8f8;
                border-left: 4px solid transparent;
                padding: 10px 20px;
                &:hover {
                  background: #ebebeb;
                  border-left: 4px solid #3498db;
                }
              }
            }
          }
        }
      }
    }
  }

  .hamburger-card-desk-top {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    @include mq($until: tablet) {
      display: none;
    }

    .navigation {
      .desk-ul {
        list-style: none;
        margin: 0px;
        padding: 0px;
        .desk-li {
          display: -webkit-box;
          margin-bottom: calc(20px + 1vmin);
          .desk-link {
            display: flex;
            background: var(--color-bg-primary);
            width: calc(100px + 5rem);
            height: fit-content !important;
            padding: 5vmin 15px;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            transition: 0.2s linear;
            color: var(--color-primary);
            &:hover {
              background: var(--color-bg-primary);
              color: var(--color-primary);
            }
            svg {
              margin-left: 5px;
              width: calc(10px + 0.2rem);
            }
            i.fa {
              width: 16px;
              text-align: center;
              margin-right: 5px;
              float: right;
              height: fit-content !important;
              &:before {
                height: fit-content !important;
              }
            }
          }
        }
      }
    }

    .sub-menu {
      @include sec-grid-wrapper-sub-menu-mix;
      width: 100%;
      display: none;
      transition-property: sliding-vertically;
      &:target {
        display: grid;
        scroll-padding: 10px;
        padding-left: 3vmin;
      }
      .menu-item {
        width: fit-content;
        width: 100%;
        text-align: center;
        a {
          background: var(--color-bg-primary);
          border-left: 4px solid transparent;
          padding: 10px 20px;
          display: block;
          width: 100%;
          padding: 5vmin 15px;
          color: var(--color-primary);
          &:hover {
            background: var(--color-bg-primary);
          }
        }
      }
    }
  }
}

// start footer
footer {
  background-color: var(--color-primary);
  color: var(--color-primary-gary-tow);  
  position: relative;
  // padding-top: 12rem !important;
  padding-top: calc(8px + 9rem) !important;
  .pwa-section {
    display: grid;
    justify-content: center;
    position: absolute;
    padding: 3rem;
    background: #fafafe;
    border-radius: 3px;
    text-align: center;
    top: calc(-10% + -13vmin);
    left: 50% !important;
    transform: translateX(-50%) !important;
    z-index: 9;
    box-shadow: 2px -2px 20px rgb(0 0 0 / 10%);
    @media screen and (max-width: 600px) {
      left: 0 !important;
      transform: none !important;
    }
    @media screen and (max-width: 450px) {
      top: calc(-10% + -30vmin) !important;
    }
    .sec-title-wrapper {
      .sec-title {
        color: var(--color-primary);
        text-transform: capitalize !important;
      }
    }
    .content {
      color: var(--color-primary);
    }

    .pwa-link-button {
      background: var(--color-primary);
      color: #ccc;
      padding: calc(4px + 0.2rem) calc(4px + 0.5rem);
      border-radius: 3px;
    }
  }

  .footer-logo-wrapper {
    a {
      svg {
        height: calc(20px + 2vmin);
        filter: invert(100%);
      }
    }
  }
  .footer-content {
    p {
      color: inherit;
      font-family: var(--secondary-font);
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
    }
  }

  .footer-title {
    font-size: 16px;
    color: var(--color-white);
    position: relative;
    font-family: var(--secondary-font);
    &:after {
      content: "";
      position: absolute;
      width: calc(20px + 2vmin);
      height: 1px;
      border-bottom: 1px solid var(--color-white) !important;
      top: 50%;
      transform: translateX(10px);
    }
  }
  .footer-nav {
    margin: 0px;
    list-style: none;
    padding: 0px;
    li {
      line-height: 2;
      a {
        color: inherit;
        text-decoration: none;
        &:hover {
          color: var(--color-white);
        }
      }
    }
  }

  .copy-right {
    padding-top: 2rem;
    P {
      font-family: var(--secondary-font);
      color: inherit;
      a {
        color: var(--color-white);
      }
    }
  }
}

.grid-center {
  display: grid;
  place-content: center;
}

.formControl {
  input {
    width: 100%;
    border: 1px solid #f0f0f0;
    height: 3rem;
    margin: 10px 0;
    padding: 15px;
  }
  textarea {
    border: 1px solid #f0f0f0;
    width: 100%;
    padding: 15px;
    margin: 10px 0;
    height: 10rem;
    max-height: 15rem;
    min-height: 5rem;
  }
}
.btn {
  background: var(--color-primary);
  width: 100%;
  color: var(--color-white) !important;
  font-size: 21px;
}
.responseMessage {
  padding: 15px 0;
  text-align: center;
  font-size: 1.6rem;
  .success_msg {
    color: green;
  }
  .error_msg {
    color: red;
  }
}
.loadingIcon {
  display: none;
  img {
    width: 5%;
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.wpcf7-form {
  display: none;
}
.notfound {
  display: block;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  .redirect {
    background: #000;
    width: 100%;
    color: #fff;
    padding: 0.6rem 1.5rem;
    font-size: 20px;
    :hover {
      color: #fff;
    }
  }
}

.loadMore {
  background-color: var(--color-primary);
  color: #fff !important;
  text-align: center;
  cursor: pointer;
  padding: 15px;
  display: block;
}

#search-sec {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: var(--color-white);
  z-index: 99;
  height: 100vh;
  .search-cross {
    float: right;
    margin-top: 1rem;
    svg {
      width: 20px;
      height: 20px;
      fill: var(--color-primary);
    }
  }
  .post-grid-wrapper {
    gap: 2rem;
    height: 100%;
    overflow-y: scroll;
    max-height: 70vh;
    .c-recent-card {
      display: flex;
      flex-direction: row;
      text-decoration: none;
      justify-content: flex-start;
      margin-bottom: calc(10px + 1.5vmin) !important;
      width: 48%;
      @media only screen and (max-width: 990px) {
        width: 100%;
      }
      .c-recent-card-img-wrapper {
        width: calc(3rem + 5vmin) !important;
        min-width: calc(3rem + 5vmin) !important;
        height: calc(3rem + 5vmin) !important;
        display: block;
        img {
          @include img-mix;
        }
      }
      .c-card-content {
        margin-left: calc(10px + 0.5vmin);
        display: flex;
        flex-direction: column;
        justify-content: center;
        .c-c-c-title {
          color: var(--color-primary);
          font-size: calc(12px + 0.5vmin);
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          font-family: var(--secondary-font);
        }
        .c-dsc {
          font-size: calc(10px + 0.5vmin);
          margin-bottom: 0;
          span {
            color: #6b6b6b;
          }
        }
      }
    }

    // .c-card-three {
    //   display: inline-grid;
    //   width: 17.5%;
    //   h3 {
    //     color: var(--color-primary);
    //     font-size: 0.8rem;
    //     line-height: 1.8rem;
    //   }
    //   span {
    //     color: var(--color-primary-gary-tow);
    //   }
    // }
  }
}

.header-search-form {
  height: 100%;
  font-size:calc(10px + 0.8rem);
  padding:0.5rem 1rem;
  form {
    height: 100%;
    input {
      height: 100%;
      width: calc(10px + 28vmin);
      font-size: calc(12px + 1vmin);
      &:focus-visible {
        outline: none !important;
      }
    }
  }
}

.text-success {
  color: green; 
}
.text-error {
  color: red;
}